let BaseUrl = {};
switch (process.env.NODE_ENV) {
  case "development":
    BaseUrl = {
      dev: "https://sartoren.pythonanywhere.com",
      // dev: "https://e898-103-173-221-254.ngrok-free.app",
      // dev: "https://backend.bakeone.in",
    };
    break;
  case "production":
    BaseUrl = {
      dev: "https://sartoren.pythonanywhere.com",
    };
    break;
  default:
    BaseUrl = {
      dev: "https://sartoren.pythonanywhere.com",
    };
    break;
}

export default BaseUrl;
